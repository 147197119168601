.loader {
  background-color: white !important;
}

.loader div {
  background: var(--color-primary) !important;
}
.barContainer {
  display: flex;
  height: 300px;
  width: 99%;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.bar {
  padding: 0 0 10px 0 !important;
  width: 50%;
  height: 100%;
  margin-top: 0;
  background-color: white !important;
}
