.footer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #3d3d3d;
  justify-content: space-between;
  height: 5rem;
  padding: 0 15%;
}

.footer-img {
  width: 20%;
}

.footer-btn {
  margin-top: 0 !important;
  line-height: 1;
}
