.overlay {
  background-color: rgba(0, 0, 0, 0.429);
  height: 100vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
}

.heading {
  display: flex;
  align-items: center;
}
.close-icon {
  cursor: pointer;
  margin-left: auto;
  display: block;
}

.preview-file {
  width: 90vw;
}

.form-group {
  position: absolute;
  transform: translate(-50%, -50%);
  height: fit-content;
  max-height: 90vh;
  min-width: 40vw;
  width: max-content;
  top: 50%;
  left: 50%;
  z-index: 100;
  overflow: hidden;
}

.form-group h1 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1rem;
}

.formContainer {
  display: flex;
  align-items: center;
}

.form .text-field {
  width: 90%;
  margin-right: 1%;
}

.form-btn {
  width: 10%;
  /* display: inline-block; */
  margin: 1rem 0;
  height: 3.8rem;
  border-radius: 5px;
}

.form-enabled-btn {
  background-color: green;
  color: white;
  transition: all 0.2s;
}
.btn {
  width: 100px !important;
  height: 30px !important;
  padding: 0 10px;
  margin: 10px 0;
  margin-left: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: var(--color-primary);
  border-radius: 0 !important;
  border: 2px solid var(--color-primary) !important;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: var(--color-primary);
  color: white;
  transition: all 0.3s ease;
}

.uploadFile {
  width: 100%;
  height: 3.1rem;
  padding: 0 10px;
  /* margin: 10px 0; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: var(--color-success);
  border: 2px solid var(--color-success);
  transition: all 0.3s ease;
  cursor: pointer;
}

.uploadFile input {
  display: none;
}

.uploadFile:hover {
  background-color: var(--color-success);
  color: white;
  transition: all 0.3s ease;
}

.fileCard {
  height: 150px;
  width: 150px;
  border: 3px solid var(--color-info-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.fileCard .icon {
  color: var(--color-info-dark);
  font-size: 7rem;
}

.fileCard .name {
  margin: 5px 10px;
  text-align: center;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
}
.options .title {
  font-size: 1.4rem;
}

.selected {
  color: var(--color-success);
  border-color: var(--color-success);
}

.selectedIcon {
  color: var(--color-success) !important;
}

.loader {
  background-color: white !important;
}

.loader div {
  background: var(--color-primary) !important;
}

.loadingIcon {
  margin-left: 5px;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.disabled {
  width: 100px !important;
  height: 30px !important;
  padding: 0 10px;
  margin: 10px 0;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0.24);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: lightgray;
  border-radius: 0 !important;
  border: 2px solid lightgray !important;
  transition: all 0.3s ease;
  cursor: no-drop;
}
.disabled:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.navigator {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}
.navigator .icon {
  font-size: 2rem;
  cursor: pointer;
}
