.sidebar {
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 89vh;
  /* position: relative; */
  top: 3rem;
  margin-top: auto;
}

h3 {
  font-weight: 500;
}

.sidebar div {
  cursor: pointer;
  display: flex;
  color: white;
  margin-left: 1.2rem;
  gap: 1rem;
  align-items: center;
  position: relative;
  height: 3.7rem;
  transition: all 300ms ease;
}

.sidebar div span {
  font-size: 1.6rem;
  transition: all 300ms ease;
}

.sidebar div:last-child {
  position: absolute;
  bottom: 1rem;
  width: 100%;
}

.sidebar div.active {
  background: var(--color-light);
  color: var(--color-primary);
  margin-left: 0;
}

.sidebar div.active:before {
  content: "";
  width: 3px;
  height: 100%;
  background: var(--color-primary);
}

.sidebar div.span {
  color: var(--color-primary);
  margin-left: calc(1rem - 3px);
}

.sidebar div:hover {
  color: var(--color-primary);
}

/* .sidebar div:hover span {
  margin-left: 0;
} */
