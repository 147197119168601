.filter-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  /* max-height: 300px; */
}

.filter {
  margin-bottom: 5px;
}

.filter select {
  width: 100%;
}

.btn {
  margin-top: auto;
}

input[type="range"] {
  height: 20px;
  appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animation-duration: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #74a9d8;
  border-radius: 0px;
  border: 0px solid #010101;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 1px solid #00001e;
  height: 13px;
  width: 25px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #74a9d8;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animation-duration: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #74a9d8;
  border-radius: 0px;
  border: 0px solid #010101;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 1px solid #00001e;
  height: 13px;
  width: 25px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animation-duration: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #74a9d8;
  border: 0px solid #010101;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-fill-upper {
  background: #74a9d8;
  border: 0px solid #010101;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000031;
  border: 1px solid #00001e;
  height: 13px;
  width: 25px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #74a9d8;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #74a9d8;
}
