.charts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 400px;
  gap: 1rem;
}

.map-filter {
  display: flex;
  height: 350px;
  gap: 1rem;
  margin-top: 1rem;
}

.filter {
  height: 350px;
  width: 35%;
  display: inline-block;
}

.table-container {
  margin: 1rem 0;
  width: 100%;
  height: 450px;
}
.table-heading {
  display: flex;
  justify-content: space-between;
}
