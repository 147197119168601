.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.spacing {
  margin-bottom: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.custom-marker-cluster {
  /* display: flex; */
  /* align-items: center; */
  padding: -2px 0;
  background: rgb(108, 108, 108);
  border-radius: 50%;
  color: #ffffff;
  height: 33px;
  line-height: 30px;
  text-align: center;
  width: 33px;
  opacity: 0.7;
  border: 3px solid rgb(246, 255, 125);
}

.legend-heading {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
