.question-container {
  width: 100%;
  border-left: 8px solid transparent;
  border-top: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  padding-left: 1rem;
  text-align: left;
  font-size: 1.1rem;
  height: 48px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.selected {
  height: 96px;
  font-weight: bolder;
  border-left-color: #14b9c17c;
  transition: 0.2s ease-in;
}

.question {
  padding: 1rem 1.25rem 0.3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
}

.answer {
  padding-left: 1rem;
  font-weight: 400;
  transition: 0.2s ease-in;
}
