.pie {
  width: 33%;
  padding: 0;
}

.pie-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 4rem;
  width: 100%;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
  background-color: #3d3d3d;
  color: white;
}

.filter {
  width: 90%;
  margin: 10px;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.textInput {
  display: inline-block;
  width: 100%;
  font-size: 1.1rem;
  background-color: white;
  border-radius: 10px;
  border: solid 2px var(--color-info-dark);
  padding: 5px 5px;
}

.btn {
  display: inline-block;
  width: 50%;
  height: min-content;
}

.pie-chart {
  height: 80%;
}
