.map {
  display: flex;
  margin: 1rem 0;
  height: 400px;

  width: 100%;
  z-index: 0;
  width: auto;
}

.map .mappy {
  height: 100%;
  width: 100%;
  z-index: 0;
  padding: var(--card-padding);
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}
