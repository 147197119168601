.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.4rem;
  padding: 0 1rem;
}

.logo {
  display: none;
  gap: 0.18rem;
}

.logo img {
  width: 100%;
  height: 2rem;
}

.icon {
  display: flex;
  color: white;
}

.icon:hover {
  display: flex;
  color: var(--color-primary);
  transition: all 0.2s ease;
  cursor: pointer;
}