.toggle {
  text-align: center;
  margin-bottom: 20px;
}

.run_button_container {
  display: flex;
  justify-content: center;
  margin: 25rem 0;
}

.run_button {
  margin: 0 10px;
  padding: 2rem 4rem;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 1rem;
  text-transform: uppercase;
  background: #12a6ad;
  color: white;
  border: none;
  cursor: pointer;
  width: 30rem;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.9); /* Add shadow */
  transition: box-shadow 0.3s ease, background 0.2s ease; /* Add smooth transition for both properties */
}

.run_button:hover {
  background: #03b9c2b6;
  color: white;
}

.Loader {
  background-color: white !important;
}

.Loader div {
  background: var(--color-primary) !important;
}
