.map {
  display: flex;
  height: 100%;
  z-index: 0;
  width: 65%;
}

.map .mappy {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  z-index: 0;
  padding: var(--card-padding);
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}
