.filter-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-evenly;
  overflow: hidden;
  max-height: 300px;
}

.btn {
  margin-top: auto;
}
