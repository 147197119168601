.card {
  background: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  /* margin-top: 1rem; */
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}

.card:hover {
  box-shadow: none;
}
