.loader {
  background-color: white !important;
}

.loader div {
  background: var(--color-primary) !important;
}

.mapAndPie {
  display: flex;
  width: 100%;
  height: 350px;
  gap: 0.7rem;
  margin: 0.7rem 0;
}

.barContainer {
  display: flex;
  height: 450px;
  width: 99%;
  justify-content: space-between;
  gap: 0.7rem;
  margin-bottom: 1rem;
}

.bar {
  padding: 0 0 10px 0 !important;
  width: 50%;
  height: 100%;
  margin-top: 0;
  background-color: white !important;
}
