.bar-heading {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 0 10px;
  height: 10%;
  width: 100%;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
  background-color: #404e66;
  color: white;
}

.graph {
  width: 50%;
}
