.feature-menu {
  width: 80%;
  margin: 0 auto;
  height: 29rem;
  display: flex;
}

.video-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29rem;
  width: 100%;
}

.options-list {
  font-size: 1.4rem;

  margin-right: 4rem;
  width: 30rem;
}

.options-list li {
  display: flex;
  align-items: center;
  height: 3.5rem;
  border-left: 8px solid transparent;
  padding-left: 15px;
  cursor: pointer;
}
.options-list li:not(:first-child),
.options-list li:not(:last-child) {
  margin: 1rem 0;
}

.video {
  width: 90%;
}
