.dropdown-container {
  display: flex;
  align-items: center;
}

.dropdown {
  margin-left: auto;
}

.dropdown-icon {
  font-size: 1.5rem;
}
.dropdown-icon:hover {
  background-color: rgba(154, 154, 154, 0.662);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all ease;
}

.dropdown-list {
  position: absolute;
  background-color: white;
  border: 1px solid lightgrey;
  right: 40px;
  height: fit-content;
  font-size: 1.1rem;
  z-index: 5;
}

.dropdown-list ul {
  background-color: white;
}

.dropdown-list ul li {
  padding: 10px 5px;
  cursor: pointer;
}
.dropdown-list ul li:hover {
  background-color: rgb(224, 224, 224);
}

.heading {
  margin-bottom: 1rem;
}
