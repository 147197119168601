.navbar {
  position: fixed;
  top: 0;
  right: 0;
  height: 4rem;
  box-shadow: none;
  border-bottom: 2px solid lightgray;
  left: 16rem;

  display: flex;
  align-items: center;
  z-index: 1;
  border-radius: 0;

  transition: all 0.5s ease;
}

.navbar-collapsed {
  left: 4rem;
  transition: all 0.5s ease;
}

.avatar {
  margin-left: auto;
}

.avatar .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: rgb(57, 47, 47);
  color: #fff;
  /* text-align: center; */
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 50px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.avatar:hover .tooltiptext {
  visibility: visible;
}
