.faq {
  width: 90%;
  margin: 3rem auto;
  margin-bottom: 5rem;
  text-align: center;
  height: 350px;
}

.list {
  width: 90%;
  margin: 0 auto;
}
