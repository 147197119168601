.loader {
  background-color: white !important;
}

.loader div {
  background: var(--color-primary) !important;
}

.mapAndPie {
  display: flex;
  width: 100%;
  height: 350px;
  gap: 0.7rem;
  margin: 0.7rem 0;
}

.barContainer {
  display: flex;
  height: 450px;
  width: 99%;
  justify-content: space-between;
  gap: 0.7rem;
  margin-bottom: 1rem;
}

.bar {
  padding: 0 0 10px 0 !important;
  width: 50%;
  height: 100%;
  margin-top: 0;
  background-color: white !important;
}

.run_button_container {
  display: flex;
  justify-content: center;
  margin: 25rem 0;
}

.run_button {
  margin: 0 10px;
  padding: 2rem 4rem;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 1rem;
  text-transform: uppercase;
  background: #12a6ad;
  color: white;
  border: none;
  cursor: pointer;
  width: 30rem;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.9); /* Add shadow */
  transition: box-shadow 0.3s ease, background 0.2s ease; /* Add smooth transition for both properties */
}

.run_button:hover {
  background: #03b9c2b6;
  color: white;
}
