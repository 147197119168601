.filter {
  display: flex;
  font-size: 1rem;
  align-items: baseline;
  justify-content: space-evenly;
  margin-bottom: 0.7rem;
}

select {
  width: 110px;
  font-size: 1.1rem;
  background-color: white;
  border-radius: 10px;
  border: solid 2px var(--color-info-dark);
  padding: 5px 5px;
}

option {
  margin-left: 10px;
}

.btn {
  margin-left: 25px;
  cursor: pointer;
}
