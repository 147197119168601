.referral-container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.filter {
  height: 400px;
  width: 35%;
  display: inline-block;
}

.textInput {
  width: 100%;
  font-size: 1.1rem;
  background-color: white;
  border-radius: 10px;
  border: solid 2px var(--color-info-dark);
  padding: 5px 5px;
}

.data-grid-container {
  width: 67%;
}

.table-group {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  height: 650px;
}

.table-container {
  font-size: 1rem;
  width: 65%;
}

.table-heading {
  display: flex;
  justify-content: space-between;
}
.table-head {
  border-bottom: 1.5px solid #404e66;
}

.table-cell-heading {
  font-weight: bold;
  color: #121f43;
  font-family: "Montserrat", sans-serif;
}

.main-content {
  color: #121f43;
  font-weight: 500;
}

.supporting-content {
  color: #a4b0be;
}

.download {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  width: 33% !important;
}
