.errorOverlay {
  background-color: rgba(0, 0, 0, 0.429);
  height: 100vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
}
.error {
  position: absolute;
  transform: translate(-50%, -50%);
  height: fit-content;
  width: 70%;
  top: 50%;
  left: 50%;
  z-index: 100;
}
.error h1 {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 2px solid red;
}

.error .close {
  margin-left: auto;
  cursor: pointer;
}

.error p {
  margin: 10px 0 20px 0;
}

.error span {
  margin-left: 10px;
}
