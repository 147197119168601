.map {
  display: flex;
  margin: 1rem 0;
  height: 500px;
  z-index: 0;
  width: 65%;
}

.map .mappy {
  border-radius: var(--card-border-radius);
  height: 100%;
  width: 100%;
  z-index: 0;
  padding: var(--card-padding);
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
