.pie {
  width: 35%;
  padding: 0;
}

.pie-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 3.5rem;
  width: 100%;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
  background-color: #3d3d3d;
  color: white;
}

.pie-chart {
  height: 70%;
}
